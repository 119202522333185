<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <!-- <titlebar :name="name" /> -->
    <!-- 上方搜索栏,按钮 -->
    <div class="top_box">
      <div class="top_input">
        <van-icon name="search" />
        <input type="text" placeholder="输入商品名称或条码" v-model="param" />
      </div>
      <van-button class="top_btn" @click="params.page = 1; getGoodsList()"> 搜索 </van-button>
      <van-button class="top_btn" @click="toJump(2)" v-if="!batchDelFlag">
        分类管理
      </van-button>
      <van-button class="top_btn" @click="toJump(3)" v-if="!batchDelFlag">
        单位管理
      </van-button>
      <div style="margin-left: auto;">
        <van-button class="top_btn" v-if="!printFlag && !batchDelFlag" @click="printFlag = true" color="#3DB956">打印标签</van-button>
        <van-button class="top_btn" v-if="(!batchDelFlag && shop.user_model == 1) && !printFlag" @click="batchDelFlag = true" color="#ee0a24"> 批量删除 </van-button>
        <van-button class="top_btn" style="background: #999;" v-if="batchDelFlag || printFlag" @click="batchDelFlag = false; printFlag = false;"> 取消 </van-button>
        <van-button class="top_btn" v-if="batchDelFlag || printFlag" @click="selectAll"> 全选 </van-button>
        <van-button class="top_btn" v-if="batchDelFlag && shop.user_model == 1" @click="batchDel" color="#ee0a24"> 删除选中 </van-button>
        <van-button class="top_btn" v-if="printFlag" @click="toPrintGood" color="#3DB956">打印选中</van-button>
      </div>
    </div>
    <!-- 分类列表 -->
    <div class="tab_list" :class="{ index_hight: categoryList.length > 8 }">
      <van-button v-for="(item, index) in categoryList" :key="index" class="tab_btn" :class="{ choose: categoryId == item.id }" @click="chooseClassify(item)">
        {{ item.name }}
      </van-button>
    </div>
    <!-- 商品列表 -->
    <div class="index_list">
      <div class="index_box addpro-index-box" @click="toJump(1)" v-if="!batchDelFlag && shop.user_model == 1 && !printFlag">
        <van-icon name="plus" size="4.6rem" class="mg-rt25" color="#333" />
        新增商品
      </div>
      <div v-for="(item, i) in goodsList" :key="i" @click="goToeditorProduct(item)" class="index_box">
        <img :src="item.img" class="index_img" />
        <div>
          <div class="goodsName">{{ item.name }}</div>
          <div class="goodsstock" v-if="item.pro_type == 1">库存：{{ item.stock }}{{ item.dw }}</div>
          <div class="goodsPrc">售价：¥{{ item.fact_price }}</div>
          <div class="goodsPrc" v-if="item.member_price && item.member_price > 0">会员价：¥{{ item.member_price }}</div>
        </div>
        <!-- <div class="icon-con">
          <img src="../../static/online.png" class="icon" v-if="item.online === 1" />
          <img src="../../static/kucun.png" class="icon" v-if="item.dw == 'kg'" />
        </div> -->
        <van-checkbox class="del-checkbox" icon-size="3rem" v-if="batchDelFlag || printFlag" :value="item.delChoosed" shape="square" />
      </div>
    </div>
    <div style="width:100%;height:5rem;background:#fff">
      <van-pagination v-model="params.page" :total-items="total" :items-per-page="params.limit" @change="pageChange" force-ellipses />
    </div>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { Toast } from "vant"
import { mapGetters } from 'vuex';

export default {
  components: {
    titlebar: titlebar,
  },
  data() {
    return {
      chooseIndex: "", //选中的分类
      goodsList: [], // 商品列表
      categoryList: [], // 商品分类列表
      categoryId: "", // 选择的商品分类ID
      param: "", // 商品名称或条码
      name: "",
      batchDelFlag: false, // 批量删除flag
      lastCategoryId: undefined, // 从商品详情回来储存的分类ID
      params: {
        page: 1,
        limit: 20,
      },
      total: 0,
      printFlag: false,
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'}),
  },
  async created() {
    this.$store.commit('updatePrinttagGoodList', []);
    await this.getCategoryList();
    this.getGoodsList();
  },
  methods: {
    pageChange() {
      this.getGoodsList();
    },
    // 拉取商品分类列表
    getCategoryList() {
      return new Promise((resolve, reject) => {
        this.$api
          .goodsType({
            shop_id: localStorage.getItem("shop_id"),
          })
          .then((res) => {
            if (res.code == 1) {
              this.categoryList = res.data.list || [];
              if (this.lastCategoryId) {
                this.categoryId = this.lastCategoryId
              } else {
                this.categoryId = res.data.list.length > 0 ? res.data.list[0].id : "";
              }
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            console.log("首页拉取商品分类 error：", error);
            reject();
          });
      });
    },
    // 拉取商品列表
    getGoodsList() {
      let query = {
        // shop_id: localStorage.getItem('shop_id'), // 门店ID
        shop_id: localStorage.getItem("shop_id"), // 门店ID
        c_id: this.categoryId, // 分类ID -1获取平台全部商品 0没有分类id则是获取全部门店商品 大于0是获取指定分类下面的商品
        // c_id: -1,
        param: this.param,
        page: this.params.page,
        limit: this.params.limit,
      };
      this.$api.goodsList(query).then((res) => {
        this.goodsList = res.data.list;
        this.total = res.data.count;
      });
    },
    // 跳转
    toJump(type) {
      switch (type) {
        case 1:
          this.$router.push({
            path: "/addProduct",
            query: { name: "addPro", categoryId: this.categoryId },
          });
          break;
        case 2:
          this.$router.push({
            path: "/ShopCategory",
            query: { name: "ShopCategory" },
          });  
          break;
        case 3:
          this.$router.push({
            path: '/ShopUnit',
            query: { name: 'ShopUnit' },
          })
          break;
      }
    },
    // 跳转至新增商品
    goToeditorProduct(item) {
      if (this.batchDelFlag || this.printFlag) {
        item.delChoosed ? item.delChoosed = false : this.$set(item, 'delChoosed', true)
        return
      }
      this.$router.push({
        path: "/addProduct",
        query: {
          name: "editorProduct",
          id: item.id,
          categoryId: this.categoryId
        },
      });
    },
    // 切换分类
    chooseClassify(item) {
      this.batchDelFlag = false
      this.categoryId = item.id;
      this.getGoodsList();
    },
    batchDel() {
      let delIdArr = []
      this.goodsList.forEach(item => {
        if (item.delChoosed) {
          delIdArr.push(item.id)
        }
      })
      if (!delIdArr.length) return Toast('请选择要删除的商品');
      this.$api.delShopGoods({
        p_id: delIdArr.join(',')
      }).then(res => {
        if (res.code === 1) {
          for (let i = 0; i < this.goodsList.length; i++) {
            if (delIdArr.includes(this.goodsList[i].id)) {
              this.goodsList.splice(i, 1)
              i--
            }
          }
        }
      })
    },
    // 前往打印选中
    toPrintGood() {
      let good_arr = []
      this.goodsList.forEach(item => {
        if (item.delChoosed) {
          item.print_num = 1;
          good_arr.push(item);
        }
      })
      if (!good_arr.length) return Toast('请选择要打印的商品');
      console.log('good_arr:', good_arr);
      this.$store.commit('updatePrinttagGoodList', good_arr);
      this.$router.push({
        name: 'toprintForGood',
        query: {
          name: 'toprintForGood',
        }
      })
    },
    // 全选
    selectAll() {
      this.goodsList.forEach(item => {
        this.$set(item, 'delChoosed', true)
      })
    }
  },
  watch: {
    batchDelFlag(n) {
      if (!n) {
        this.goodsList.forEach(item => {
          if (item.delChoosed) item.delChoosed = false
        })
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.query.categoryId) {
        vm._data.lastCategoryId = from.query.categoryId
      }
    })
  }
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;

  .top_box {
    padding: 2rem;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .top_input {
      width: 45rem;
      height: 6.8rem;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      // justify-content: center;
      font-size: 2.4rem;
      font-weight: 400;
      color: #999999;
      border-radius: 0.8rem;
      padding-left: 2.5rem;

      input {
        background-color: #f5f5f5;
        margin-left: 2rem;
      }
    }

    .top_btn {
      padding: 0 2rem;
      border-radius: 0.8rem;
      height: 6.8rem;
      background: #1588f5;
      font-size: 2.4rem;
      color: #ffffff;
      margin-left: 2rem;
    }
  }

  .tab_list {
    margin: 0 2rem;
    width: calc(100% - 4rem);
    // background-color: #1588f5;
    display: grid;
    grid-template-columns: repeat(8, 12.5%);
    height: 8rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab_btn {
      height: 7.5rem;
      background-color: #F4F4F4;
      border: none;
      border-radius: 0.8rem;
      font-size: 2.6rem;
      font-weight: 500;
      color: #333;
      line-height: 3.7rem;
      margin-right: 1.5rem;
      margin-bottom: 1rem;
    }

    .choose {
      background-color: #1588f5;
      color: #fff;
    }
  }

  .index_hight {
    height: 16rem !important;
  }

  .index_list {
    width: calc(100% - 4rem);
    // height: 50rem;
    flex: 1;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    display: grid;
    grid-template-columns: repeat(5, 19%);
    grid-template-rows: repeat(auto-fill, 16rem);
    grid-gap: 1.5rem 1%;
    overflow-x: hidden;
    overflow-y: scroll;

    .addpro-index-box {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.8rem;
      color: #1588F5;
      font-weight: bold;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    .index_box {
      position: relative;
      // width: 32.9rem;
      // height: 16rem;
      background: #ffffff;
      box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
      display: flex;
      align-items: center;
      text-align: left;
      position: relative;

      .index_img {
        min-width: 12rem;
        min-height: 12rem;
        max-width: 12rem;
        max-height: 12rem;
        margin-right: 3rem;
      }

      .goodsName {
        width: 15rem;
        font-size: 2rem;
        font-weight: bold;
        color: #333333;
        line-height: 3rem;
        margin-bottom: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .goodsstock {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 0.3rem;
        //   color: #1588f5;
        line-height: 3rem;
      }
      .goodsPrc {
        font-size: 1.5rem;
        font-weight: 500;
        color: #1588f5;
        line-height: 3rem;
      }
      .icon-con {
        position: absolute;
        bottom: 0;
        right: 0;
        .icon {
          width: 3rem;
          margin-left: 0.4rem;
          /* height: 2rem; */
        }
      }
      .del-checkbox {
        position: absolute;
        left: 1rem;
        top: 1rem;
        background: #fff;
      }
    }

    .isadd-icon {
      background: rgba(245, 176, 135, 0.2) !important;
    }

    .addGoods {
      width: 31.9rem;
      height: 12rem;
      background: #ffffff;
      box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
      margin-bottom: 1.9rem;
      text-align: center;
      font-size: 2.6rem;
      font-weight: 400;
      color: #333333;
      line-height: 12rem;
    }

    .ft_30 {
      font-size: 3rem;
    }
  }
}
</style>
